import { getAllPagesSlugs, getGlobalData, getPage } from "@api/handlers";
import { layoutAdapter } from "@layout/Layout.adapter";
import { dynamicPageAdapter } from "@page-components/dynamic-page/DynamicPage.adapter";
import { Pages } from "@page-components/index";
import { handleStaticProps } from "@utils/handle-static/handle-static-props";
import { isPreview } from "@utils/is-preview/isPreview";
import { GetStaticPaths, GetStaticProps } from "next";

export const getStaticProps: GetStaticProps = async (context) => {
  const preview = isPreview(context);
  if (!context.locale) throw new Error("Locale is not defined");
  const page = context.params?.page;

  const response = await getPage({
    preview,
    locale: context.locale,
    slug: page,
  });
  const globalData = await getGlobalData({ locale: context.locale });
  const globalProps = layoutAdapter(globalData);
  const props = dynamicPageAdapter({
    ...response,
    preview,
  });

  return handleStaticProps({
    props: { ...props, ...globalProps },
    context,
    requiredData: response.page,
  });
};

export const getStaticPaths: GetStaticPaths = async () => {
  const slugs = await getAllPagesSlugs({ preview: false });
  return {
    paths: slugs.map((page) => ({ params: { page } })),
    fallback: "blocking",
  };
};

export default Pages.DynamicPage;
